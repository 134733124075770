<template>
        <ODropdown ref="dropdown">
            <template #default="{ target, open}">
                <button :ref="target"  @click="open" class="btn btn-sm btn-link">
                    <i class="bi bi-list"></i>
                </button>
            </template>
            <template #dropdown="scope">
                <div class="dropdown-menu show" :ref="scope.container">
                    <button v-if="shouldShowMenuItem('filters')"  class="dropdown-item" :title="$t('Filters')" @click="openMenuModal('filters')">
                        <i class="bi bi-funnel me-1"></i>
                        {{$t('Filters')}}
                    </button>
                    <button v-if="shouldShowMenuItem('columns')" class="dropdown-item" :title="$t('Column Chooser')" @click="openMenuModal('columns')">
                        <i class="bi bi-layout-three-columns me-1"></i>
                        {{$t('Columns')}}
                    </button>
                    <template v-if="!gridControl.isLookup">
                        <button v-if="shouldShowMenuItem('export')" class="dropdown-item" :title="gridControl.importData ? $t('Export / Import Data') : $t('Export Data')" @click="openMenuModal('export')">
                            <i class="bi bi-database me-1"></i>
                            {{
                                gridControl.importData
                                    ? $t('Export / Import')
                                    : $t('Export')
                            }}
                        </button>
                        <button v-if="gridControl.dataObject?.layoutManager && shouldShowMenuItem('layouts')" class="dropdown-item" :title="$t('Layouts')" @click="openMenuModal('layouts')">
                            <i class="bi bi-grid-1x2 me-1"></i>
                            {{$t('Layouts')}}
                        </button>
                        <div class="dropdown-divider"></div>
                        <template v-if="gridControl.dataObject && gridControl.selectionControl.selectedRows.length > 0">
                            <button @click="copySelectedAsJSON" class="dropdown-item" :title="$t('Copy selected rows as JSON')" >
                                    <i class="bi bi-files  me-1"></i>
                                    {{$t('Copy selected rows as JSON')}}
                            </button>
                            <button v-if="gridControl.dataObject.allowDelete"
                                @click="bulkDeleteSelected" class="dropdown-item" :title="$t('Delete selected rows')" >
                                    <i class="bi bi-trash md-1"></i>
                                    {{$t('Delete selected rows')}}
                            </button>
                        
                            <div class="dropdown-divider"></div>
                        </template>
                        <button v-if="!gridControl.dataObject?.hideToggleMultiselect" class="dropdown-item" :title="$t('Toggle multiselect column')" @click="toggleMultiSelect()">
                            <i class="bi bi-check-square"></i>
                            {{$t('Toggle Multiselect Column')}}
                        </button>
                        <button class="dropdown-item"  v-if="hasUnique(gridControl.dataObject)" :title="$t('Info for current record')" @click="() => {$refs.recordInfoModal.open(); scope.close()}">
                            <i class="bi bi-info-circle me-1"></i>
                            {{$t('Record info')}}
                        </button>
                    </template>
                    <template v-if="gridControl.dataObject && gridControl.dataObject.hasPagedData && gridControl.dataObject.pagedData.enabled">
                        <div class="dropdown-divider"></div>
                        <div class="hstack px-4">
                            <span>{{ $t('Page size') }}</span>
                            <input :value="gridControl.dataObject.pagedData.pageSize" class="ms-2 form-control form-control-sm text-center" 
                                @blur="e => updatePageSize(e)"
                                @keydown.enter="e => {updatePageSize(e); scope.close();}"
                                type="number" min="0" step="1" max="500" :title="$t('Set page size')"  style="width: 60px;">
                            <button v-if="gridControl.dataObject.pagedData.pageSize !== gridControl.dataObject.pagedData.initialPageSize" 
                                @click="() => gridControl.dataObject.pagedData.pageSize = gridControl.dataObject.pagedData.initialPageSize"
                                class="btn btn-sm btn-link" :title="$t('Reset to default')">
                                <i class="bi bi-arrow-counterclockwise"></i>
                            </button>
                        </div>
                    </template>
                    <template v-if="isDeveloper">
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" :title="$t('Add debug column to the grid')" @click="() => enableDevTools()">
                            <i class="bi bi-code-slash me-1"></i>
                            {{$t('Add Debug Column')}}
                        </button>
                    </template>
                </div>
            </template>
        </ODropdown>

    <ODialog v-model:show="showGridModal" @hidden="handleModalHidden" :title="getModalTitle()" size="xl" backdrop>
        <template #header="{ title }">
            <h5 class="modal-title me-2">{{title}}</h5>
            <div class="input-group input-group-sm" style="width: auto;">
                <input v-if="searchable" v-model="searchValue" type="text" class="form-control form-control-sm" style="max-width:250px;" :placeholder="$t('Search...')">
                    <div class="input-group-text" v-if="(activeModal === 'columns' || activeModal === 'export') && columnChooserRef">
                        <input class="form-check-input mt-0" type="checkbox" v-model="selectAllColumns" :indeterminate="indeterminate"
                            :title="columnChooserRef.control.allColumnsSelected ? $t('Hide All Columns') : $t('Choose All Columns')" />
                    </div>
                </div>
            <button type="button" class="btn-close ms-auto" @click="() => showGridModal=false" aria-label="Close"></button>
        </template>
        <template #default>
            <div class="o365-dialog-body bg-body">
                <ActiveFilters v-if="activeModal === 'filters'" hideHeader
                    :dataObject="gridControl.dataObject" :gridRef="gridControl">
                    <template #filterBottom>
                        <FilterStringEditor class="mt-2" :filterObject="gridControl.filterObject"/>
                    </template>
                </ActiveFilters>
                <template v-else-if="activeModal === 'columns'">
                    <template v-if="gridControl.dataObject?.hasPropertiesData">
                        <ORowContainer class="pb-4">
                            <OColumnChooser ref="columnChooserRef" :gridRef="gridControl" disableSearch :watchTarget="columnsWatchTarget" />
                        </ORowContainer>
                        <PropertiesList :dataObject="gridControl.dataObject" listStyle="max-height: 30vh;"/>
                    </template>
                    <OColumnChooser v-else ref="columnChooserRef" :gridRef="gridControl" disableSearch :watchTarget="columnsWatchTarget" />
                </template>
                <template v-else-if="activeModal === 'export'">
                    <ExportData ref="columnChooserRef" hideHeader disableSearch :gridRef="gridControl"/>
                </template>
                <GridLayouts v-else-if="activeModal === 'layouts'" hideHeader />
            </div>
        </template>
    </ODialog>
    <ORecordInfo v-if="hasUnique(gridControl.dataObject)" :dataObject="gridControl.dataObject" ref="recordInfoModal" noDefaultSlot />
</template>


<script setup lang="ts">
import type DataGridControl from './DataGridControl.ts';
import type { Ref } from 'vue';
import { ODropdown, ODialog } from 'o365-ui-components';
import { InjectionKeys, $t } from 'o365-utils';
import { ref, inject, computed } from 'vue';
import { userSession} from 'o365-modules'

import { useAsyncComponent } from 'o365-vue-utils';
import { onMounted } from 'vue';

const ActiveFilters = useAsyncComponent('OActiveFilters', { importFn: async () => {
    const filters = await import('o365-filter-components');
    return filters.OActiveFilters;
}});

const hasUnique = (ds) =>{
    return ds && ds.fields.uniqueField && ["PrimKey","ID"].indexOf(ds.fields.uniqueField) > -1
}

const OColumnChooser = useAsyncComponent('./components.ColumnChooser.vue', { importFn: () => import('./components.ColumnChooser.vue')});
const ExportData = useAsyncComponent('ExportData', { importFn: async () => {
    const exprotModule = await import('o365-data-export');
    return exprotModule.OExportData;
}});
const GridLayouts = useAsyncComponent('./components.Layouts.vue', { importFn: () => import('./components.Layouts.vue')});
const FilterStringEditor = useAsyncComponent('FilterStringEditor', { importFn: async () => {
    const filters = await import('o365-filter-components');
    return filters.OFilterStringEditor;
}});
const PropertiesList = useAsyncComponent('PropertiesList', { importFn: async () => {
    const propertiesModule = await import('o365-data-properties');
    return propertiesModule.OPropertiesList;
}});
const ORecordInfo = useAsyncComponent('ORecordInfo', { importFn: async () => {
    const m = await import('./components.RecordInfo.vue');
    return m.default;
}});

const gridControl = inject<Ref<DataGridControl>>(InjectionKeys.dataGridControlKey, null);

const isDeveloper = userSession.isDeveloper && userSession.personId == 96470;

const showGridModal = ref (false);

type ModalType = 'filters' | 'columns' | 'export' | 'layouts' | null;
const activeModal = ref<ModalType>(null);
const dropdown = ref<InstanceType<typeof ODropdown>>(null);

const columnChooserRef = ref(null);

const searchValueRef = ref('');
const searchValue = computed({
    get() { return searchValueRef.value;},
    set(value) {
        searchValueRef.value = value;
        if (activeModal.value === 'columns' || activeModal.value === 'export') {
            columnChooserRef.value?.setSearchValue(value);
        }
    }
});

const searchable = computed(() => {
    return activeModal.value === 'columns' || activeModal.value === 'export';
});

function shouldShowMenuItem(pItem: string) {
    if (pItem == 'export' && gridControl.value?.dataObject == null) { return false; } 
    if (gridControl.value.props.hideMenuItems == null) {
        return true;
    } else {
        return !gridControl.value.props.hideMenuItems.includes(pItem);
    }
}

function openMenuModal(modal: ModalType) {
    searchValueRef.value = '';
    activeModal.value = modal;
    // modalRef.value.show();
    showGridModal.value = true;
    dropdown.value.close();
}

function getModalTitle() {
    switch(activeModal.value) {
        case 'filters':
            return $t('Filters');
        case 'columns':
            return $t('Column Chooser');
        case 'export':
            return gridControl.value.importData
                ? $t('Export / Import Data')
                : $t('Export Data');
        case 'layouts':
            return $t('Layouts');
        default:
            return '';
    }
}

function handleModalHidden() {
    searchValue.value = '';
    activeModal.value = null;
}

function toggleMultiSelect() {
    const column = gridControl.value.dataColumns.columns.find(x => x.colId === 'o365_MultiSelect');
    if (column) {
        column.hide = !column.hide;
    }
    dropdown.value.close();
}

function updatePageSize(e: Event) {
    const element = e.target as HTMLInputElement;
    const size = +(element.value || 0);
    gridControl.value.dataObject.pagedData.pageSize = size < 0
        ? 0
        : size > 500
            ? 500
            : size;
}

const columnsWatchTarget = computed(() => {
    return gridControl.value.dataColumns.columns.map(col => `${col.colId}-${col.order}`);
});

const selectAllColumns = computed({
    get() {
        return columnChooserRef.value.control.allColumnsSelected.value;
    },
    set(pValue) {
        columnChooserRef.value.control.chooseAllColumns(pValue);
    }
});

const indeterminate = computed(() => {
    return columnChooserRef.value.control.someColumnsSelected.value && !columnChooserRef.value.control.allColumnsSelected.value;
})

function bulkDeleteSelected() {
    if (gridControl.value.dataObject?.selectionControl.selectedRows.length < gridControl.value.dataObject?.selectionControl.selectedUniqueKeys.size) {
        gridControl.value.dataObject?.recordSource.bulkDeleteByPrimKeys(Array.from(gridControl.value.dataObject?.selectionControl.selectedUniqueKeys), { confirm: true });
    } else {
        gridControl.value.dataObject?.recordSource.bulkDelete(gridControl.value.dataObject?.selectionControl.selectedRows, { confirm: true });
    }

    dropdown.value.close();
}

function copySelectedAsJSON() {
    gridControl.value.dataObject?.selectionControl.copySelection(false, gridControl.value.dataColumns.columns, true);
    dropdown.value.close();
}

function enableDevTools() {
    import('./extensions.DevTools.ts').then(() => {
        gridControl.value.devTools.addDebugInfoColumn();
    })
}

onMounted(() => {
    if (gridControl.value?.utils) {
        gridControl.value.utils.openMenuModal = openMenuModal;
    }
});

</script>